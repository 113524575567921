body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .ant-table-tbody > tr:hover {
  cursor: pointer;
} */

.hoverEffect:hover {
  cursor: pointer;
  background-color: #f5f5f5; /* ホバー時の背景色 */
}

.ant-slim-table .ant-table-tbody {
  padding: 3px !important;
}
.ant-slim-table .ant-table-tbody > tr > td {
  padding: 3px !important;
}

.ant-slim-table .ant-table-thead > tr > th {
  padding: 3px !important;
}

.ant-aggregate-slim-table .ant-table-tbody {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.ant-aggregate-slim-table .ant-table-tbody > tr > td {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
