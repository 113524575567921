.question-wrapper {
  display: flex;
  align-items: center;
}

.question-score-label {
  font-size: 0.8rem;
  text-align: center;
}

.question-description {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
  margin-right: 15px;
}

.question-label {
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 90px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  color: #003288;
  background: #fff;
  border: solid 3px #003288; /*線*/
  border-radius: 10px; /*角の丸み*/
}

.question-label-selected {
  display: flex;
  flex-direction: column;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  width: 90px;
  font-size: 1rem;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  color: #fff; /*文字色*/
  background: #003288;
  border: solid 3px #003288; /*線*/
  border-radius: 10px; /*角の丸み*/
}

.question-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-text {
  font-size: clamp(13px, 1.2vw, 1rem);
}

.question-img-container {
  display: flex;
  justify-content: flex-end;
}

.question-img {
  border-radius: 8px;
  object-fit: cover;
  width: 10vw;
  max-width: 160px;
  min-width: 80px;
}
.spacer {
  display: none;
}

@media (max-width: 425px) {
  .question-wrapper {
    width: 100%;
  }

  .question-score-label {
    font-size: 0.6rem;
    text-align: center;
  }

  .question-label {
    padding: 0.5em 1em;
    margin: 0.5em 0;
    width: 70px;
    font-size: 0.7rem; /*clamp(1rem, 2vw, 1.1rem);*/
    border: solid 2px #003288;
  }

  .question-label-selected {
    padding: 0.5em 1em;
    margin: 0.5em 0;
    width: 70px;
    font-size: 0.7rem;
    border: solid 1px #003288;
  }

  .question-description {
    margin-left: 15px;
  }

  .question-row {
    flex-direction: column;
    align-items: center;
  }

  .question-img-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  .spacer {
    display: flex;
    flex-grow: 10;
  }

  .question-img {
    border-radius: 8px;
    object-fit: cover;
    min-width: 120px;
  }
}
